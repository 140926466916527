import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import cr from "../../lib/request";
import formatDate from "../../lib/formatDate";
import UpdateForm from "./UpdateForm";

type Props = {
  children: any;
  kontenId: number;
};

export default function EditableContent({ children, kontenId }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusRef = useRef(null);

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      const url = `api/content/${kontenId}`;
      cr.get(url)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen, kontenId]);

  return (
    <>
      <Box
        className="editable"
        onClick={onOpen}
        w={kontenId === 55 ? "100%" : "fit-content"}
      >
        {children}
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        initialFocusRef={initialFocusRef}
        size={"lg"}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Update Konten</ModalHeader>

          {!loading && data ? (
            <>
              <ModalBody>
                <Stack flexDir={["column", "row"]} mb={2}>
                  <Text className="detailKontenLabel">ID</Text>
                  <Text>{data.content_data.id}</Text>
                </Stack>

                <Stack flexDir={["column", "row"]} mb={2}>
                  <Text className="detailKontenLabel">Tipe konten</Text>
                  <Text>{data.content_type.type}</Text>
                </Stack>

                <Stack flexDir={["column", "row"]} mb={6}>
                  <Text className="detailKontenLabel">Terakhir diubah</Text>
                  <Text>{formatDate(data.content_data.created_at)}</Text>
                </Stack>

                <UpdateForm
                  initialFocusRef={initialFocusRef}
                  initialData={data}
                  setUpdateLoading={setUpdateLoading}
                  onClose={onClose}
                />
              </ModalBody>

              <ModalFooter pb={"10px !important"}>
                <VStack align={"stretch"} w={"100%"}>
                  <Button
                    colorScheme="ap"
                    className="lg-clicky"
                    isLoading={updateLoading}
                    type="submit"
                    form={"updateForm"}
                  >
                    Update
                  </Button>
                  <Button className="btn-clear" onClick={onClose}>
                    Cancel
                  </Button>
                </VStack>
              </ModalFooter>
            </>
          ) : (
            <VStack h={"200px"} justify={"center"} p={4} pb={12}>
              <Spinner />
            </VStack>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
