import { Box, HStack, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Container from "../components/landingPageComponents/Container";
import { Star } from "@phosphor-icons/react";
import { StatistikSection } from "../const/types";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: StatistikSection;
};

export default function Statistik({ data }: Props) {
  return (
    <VStack bg={"p.500"} py={8}>
      <Container>
        <SimpleGrid columns={[2, null, 4]} color={"white"} gap={6}>
          <Box>
            <HStack>
              <EditableContent kontenId={data.rating.value.id}>
                <Text
                  fontWeight={800}
                  fontSize={[24, null, 26]}
                  lineHeight={1.2}
                >
                  {data.rating.value.konten}
                </Text>
              </EditableContent>
              <Icon as={Star} fontSize={[24, null, 26]} weight="fill" />
            </HStack>

            <EditableContent kontenId={data.rating.narasi.id}>
              <Text opacity={0.5}>{data.rating.narasi.konten}</Text>
            </EditableContent>
          </Box>

          <Box>
            <EditableContent kontenId={data.totalLoker.value.id}>
              <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
                {data.totalLoker.value.konten}
              </Text>
            </EditableContent>

            <EditableContent kontenId={data.totalLoker.narasi.id}>
              <Text opacity={0.5}> {data.totalLoker.narasi.konten}</Text>
            </EditableContent>
          </Box>

          <Box>
            <EditableContent kontenId={data.unduhan.value.id}>
              <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
                {data.unduhan.value.konten}
              </Text>
            </EditableContent>

            <EditableContent kontenId={data.unduhan.narasi.id}>
              <Text opacity={0.5}>{data.unduhan.narasi.konten}</Text>
            </EditableContent>
          </Box>

          <Box>
            <EditableContent kontenId={data.penggunaAktif.value.id}>
              <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
                {data.penggunaAktif.value.konten}
              </Text>
            </EditableContent>

            <EditableContent kontenId={data.penggunaAktif.narasi.id}>
              <Text opacity={0.5}>{data.penggunaAktif.narasi.konten}</Text>
            </EditableContent>
          </Box>
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
