import React from "react";
import { Box, Text } from "@chakra-ui/react";
import AdminContainer from "../components/AdminContainer";
import NavHeader from "../components/NavHeader";

export default function Dashboard() {
  return (
    <AdminContainer active={[2]}>
      <NavHeader label="Dashboard" />

      <Box px={5} py={4}>
        <Text>Dashboard</Text>
      </Box>
    </AdminContainer>
  );
}
