import { Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { ArrowLeft } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";

type Props = {
  la?: any | undefined;
  label?: string | undefined;
  ra?: any | undefined;
};

export default function NavHeader({ la, label, ra }: Props) {
  return (
    <HStack
      position={"fixed"}
      top={0}
      left={0}
      px={4}
      pl={"64px"}
      w={"100%"}
      h={"50px"}
      justify={"space-between"}
      borderBottom={"1px solid var(--divider3)"}
      bg={"white"}
      zIndex={97}
    >
      <Box w={"80px"}>
        {la !== "back" && la}
        {la === "back" && (
          <IconButton
            aria-label="leftActionButton"
            icon={<Icon as={ArrowLeft} fontSize={iconSize} />}
            className="btn-clear clicky"
          />
        )}
      </Box>

      <Box>
        <Text fontWeight={600}>{label}</Text>
      </Box>

      <Box w={"80px"}>{ra && ra}</Box>
    </HStack>
  );
}
