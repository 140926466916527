import { Center, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Container from "../components/landingPageComponents/Container";
import { LayananSection } from "../const/types";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: LayananSection;
};

export default function Layanan({ data }: Props) {
  return (
    <VStack id="layananSection" py={20}>
      <Container>
        <VStack mb={12}>
          <EditableContent kontenId={data.judul.id}>
            <Text
              textAlign={"center"}
              fontSize={[32, null, 34]}
              fontWeight={800}
            >
              {data.judul.konten}
            </Text>
          </EditableContent>
        </VStack>

        <SimpleGrid columns={[1, 2, 3]} gap={4}>
          {data.layananItems?.map((layanan, i) => (
            <VStack key={i} className="layananItem">
              <EditableContent kontenId={layanan.img.id}>
                <Center className="layananItemIconBg" bg={"p.300"}>
                  <Image
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${layanan.img.konten}`}
                    className="layananItemIcon"
                  />
                </Center>
              </EditableContent>

              <VStack zIndex={2}>
                <EditableContent kontenId={layanan.narasi.id}>
                  <Text className="layananItemNarasi">
                    {layanan.narasi.konten}
                  </Text>
                </EditableContent>
              </VStack>

              <Image
                src={`${process.env.REACT_APP_MEDIA_DOMAIN}${layanan.img.konten}`}
                className="layananItemIconOverlay"
              />
            </VStack>
          ))}
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
