import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Container from "../components/landingPageComponents/Container";
import useScreenWidth from "../lib/useGetScreenWidth";
import { GooglePlayLogo } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import { HeroSection } from "../const/types";
import useScreenHeight from "../lib/useGetScreenHeight";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: HeroSection;
};

export default function Hero({ data }: Props) {
  const sw = useScreenWidth();

  return (
    <VStack
      id="berandaSection"
      minH={useScreenHeight() < 1000 ? "100vh" : ""}
      mt={"-72px"}
      overflow={"hidden"}
    >
      <Container flex={1} position={"relative"}>
        <Image
          src={"/images/bgHero.svg"}
          position={"absolute"}
          w={"1000px"}
          zIndex={-1}
          transform={"rotate(20deg)"}
          right={sw < 1000 ? "-100px" : "-620px"}
          top={sw < 1000 ? "-300px" : "-50px"}
        />

        <Stack
          flexDir={sw < 1000 ? "column-reverse" : "row"}
          gap={12}
          flex={1}
          align={sw < 1000 ? "flex-start" : "center"}
          justify={"center"}
        >
          <Box py={16} pt={sw < 1000 ? 0 : 24}>
            <HStack mb={4}>
              <Box w={"40px"} h={"2px"} bg={"p.500"} />
              <EditableContent kontenId={data.tagline.id}>
                <Text
                  color={"p.500"}
                  fontSize={[12, null, 14]}
                  fontWeight={700}
                >
                  {data.tagline.konten}
                </Text>
              </EditableContent>
            </HStack>

            <EditableContent kontenId={data.judul.id}>
              <Text
                fontWeight={800}
                fontSize={[32, null, 34]}
                lineHeight={1.4}
                mb={4}
                maxW={"300px"}
              >
                {data.judul.konten}
              </Text>
            </EditableContent>

            <EditableContent kontenId={data.narasi.id}>
              <Text lineHeight={1.8} mb={8} maxW={"500px"}>
                {data.narasi.konten}
              </Text>
            </EditableContent>

            <EditableContent kontenId={data.googlePlayLink.id}>
              <Button
                leftIcon={
                  <Icon
                    as={GooglePlayLogo}
                    fontSize={iconSize}
                    weight={"duotone"}
                  />
                }
                w={"180px"}
                h={"55px"}
                colorScheme="ap"
                pl={"12px !important"}
                className="lg-clicky"
              >
                Google Play
              </Button>
            </EditableContent>
          </Box>

          <Box
            transform={sw < 1000 ? "" : "rotate(5deg)"}
            mr={[null, null, null, "-100px"]}
            alignSelf={"center"}
            mb={"-24px"}
          >
            <HStack
              minH={"200px"}
              maxH={[
                "500px",
                null,
                null,
                useScreenHeight() < 1000 ? "100vh" : "800px",
              ]}
              // overflow={"hidden"}
              align={"flex-start"}
              className={sw < 1000 ? "gambarGila" : ""}
              gap={5}
            >
              <Box
                w={"50%"}
                h={"max-content"}
                animation={"gambarGila 30s linear infinite"}
              >
                <EditableContent kontenId={data.gambarGila.gg1.id}>
                  <Image
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${data.gambarGila.gg1.konten}`}
                  />
                </EditableContent>
              </Box>

              <Box
                w={"50%"}
                h={"max-content"}
                animation={"gambarGila 40s linear infinite"}
              >
                <EditableContent kontenId={data.gambarGila.gg2.id}>
                  <Image
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${data.gambarGila.gg2.konten}`}
                  />
                </EditableContent>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </Container>
    </VStack>
  );
}
