import { VStack } from "@chakra-ui/react";
import React from "react";

export default function BlogMenuItem(props: any) {
  return (
    <VStack
      {...props}
      justify={"center"}
      gap={4}
      p={5}
      borderRadius={8}
      cursor={"pointer"}
      className="lg-clicky"
      transition={"200ms"}
    >
      {props.children}
    </VStack>
  );
}
