import React, { useCallback, useEffect } from "react";
import { Content } from "../../const/types";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import cr from "../../lib/request";
import useTrigger from "../../globalState/useTrigger";

interface Props {
  initialFocusRef: any;
  initialData: Content;
  setUpdateLoading: (loading: boolean) => void;
  onClose: () => void;
}

export default function UpdateFormText({
  initialFocusRef,
  initialData,
  setUpdateLoading,
  onClose,
}: Props) {
  useEffect(() => {
    if (initialFocusRef && initialFocusRef.current) {
      initialFocusRef.current.focus();
    }
  }, [initialFocusRef]);

  const autoResize = useCallback(() => {
    if (initialFocusRef.current) {
      initialFocusRef.current.style.height = "auto";
      initialFocusRef.current.style.height = `${
        initialFocusRef.current.scrollHeight + 5
      }px`;
    }
  }, [initialFocusRef]);

  useEffect(() => {
    autoResize();
  }, [autoResize]);

  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      konten: initialData?.content_data?.content,
    },

    validationSchema: yup.object().shape({
      konten: yup.string().required("Konten harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      setUpdateLoading(true);
      const url = `api/content/${initialData.content_data.id}/update`;
      const payload = {
        content: values.konten,
      };
      cr.post(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            onClose();
            toast({
              title: "Update Konten Berhasil",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setTrigger(!trigger);
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Update Konten Gagal",
            description: "Maaf terjadi kesalahan",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setUpdateLoading(false);
        });
    },
  });

  return (
    <form id={"updateForm"} onSubmit={formik.handleSubmit}>
      <FormControl mb={4} isInvalid={formik.errors.konten ? true : false}>
        <FormLabel>Isi Konten</FormLabel>
        <Textarea
          ref={initialFocusRef}
          name="konten"
          placeholder="Isi Konten (teks, tautan, tautan embed)"
          value={formik.values.konten}
          onChange={formik.handleChange}
          onInput={autoResize}
        />
        <FormErrorMessage>{formik.errors.konten}</FormErrorMessage>
      </FormControl>

      {formik.values.konten && (
        <>
          <FormLabel fontStyle={"italic"}>Preview</FormLabel>
          <Box
            p={"8px 16px"}
            bg={"var(--divider4)"}
            border={"1px solid var(--divider2)"}
            borderRadius={8}
          >
            {initialData.content_type.type === "Teks" && (
              <Text>{formik.values.konten}</Text>
            )}

            {initialData.content_type.type === "Tautan" && (
              <Link isExternal href={formik.values.konten} color={"p.500"}>
                {formik.values.konten}
              </Link>
            )}

            {initialData.content_type.type === "Youtube Embed" && (
              <VStack className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={formik.values.konten}
                  title="Introduction to KUBU | Application for Indonesian Workers and Online Community"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </VStack>
            )}
          </Box>
        </>
      )}
    </form>
  );
}
