import { VStack } from "@chakra-ui/react";
import React from "react";
import SideNav from "./SideNav";

export default function AdminContainer(props: any) {
  return (
    <VStack gap={0} align={"stretch"}>
      <SideNav active={props.active} />

      <VStack
        minH={"100vh"}
        align={"stretch"}
        pt={"50px"}
        pl={"60px !important"}
        gap={0}
      >
        {props.children}
      </VStack>
    </VStack>
  );
}
