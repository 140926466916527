import React from "react";
import { Content } from "../../const/types";
import UpdateFormText from "./UpdateFormText";
import UpdateFormImage from "./UpdateFormImage";

interface Props {
  initialFocusRef: any;
  initialData: Content;
  setUpdateLoading: (loading: boolean) => void;
  onClose: () => void;
}

export default function UpdateForm({
  initialFocusRef,
  initialData,
  setUpdateLoading,
  onClose,
}: Props) {
  if (initialData.content_type.type === "Gambar") {
    return (
      <UpdateFormImage
        initialFocusRef={initialFocusRef}
        initialData={initialData}
        setUpdateLoading={setUpdateLoading}
        onClose={onClose}
      />
    );
  } else {
    return (
      <UpdateFormText
        initialFocusRef={initialFocusRef}
        initialData={initialData}
        setUpdateLoading={setUpdateLoading}
        onClose={onClose}
      />
    );
  }
}
