import {
  Box,
  HStack,
  Image,
  Link as ChakraLink,
  Text,
  VStack,
  IconButton,
  Icon,
  Link,
} from "@chakra-ui/react";
import React from "react";
import Container from "../components/landingPageComponents/Container";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { FooterSection } from "../const/types";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: FooterSection;
};

export default function Footer({ data }: Props) {
  return (
    <VStack gap={0} overflow={"hidden"} mt={20}>
      {/* <Box h={"100px"} w={"120%"} bg={"p.800"} borderRadius={"100% 100% 0 0"} /> */}

      <VStack
        bg={"linear-gradient(to bottom left, var(--p800), var(--p700))"}
        w={["200%", "180%", "160%", "140%"]}
        color={"white"}
        pb={8}
        pt={"100px"}
        borderRadius={"100% 100% 0 0"}
      >
        <VStack w={"calc(100vw - 80px)"}>
          <Container>
            <HStack gap={4} mb={4}>
              <Image src="/logo.png" h={"40px"} />
              <Text fontWeight={800} fontSize={[26, null, 28]}>
                Kubu.id
              </Text>
            </HStack>

            <EditableContent kontenId={data.emailKontak.id}>
              <Text
                as={ChakraLink}
                href={`emailto:${data.emailKontak.konten}`}
                mb={4}
              >
                {data.emailKontak.konten}
              </Text>
            </EditableContent>

            <HStack>
              <EditableContent kontenId={data.sosmedLinks.facebook.id}>
                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={data.sosmedLinks.facebook.konten}
                  icon={
                    <Icon
                      as={FacebookLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                      color={"white"}
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />
              </EditableContent>

              <EditableContent kontenId={data.sosmedLinks.twitter.id}>
                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={data.sosmedLinks.twitter.konten}
                  icon={
                    <Icon
                      as={TwitterLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                      color={"white"}
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />
              </EditableContent>

              <EditableContent kontenId={data.sosmedLinks.instagram.id}>
                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={data.sosmedLinks.instagram.konten}
                  icon={
                    <Icon
                      as={InstagramLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                      color={"white"}
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />
              </EditableContent>

              <EditableContent kontenId={data.sosmedLinks.youtube.id}>
                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={data.sosmedLinks.youtube.konten}
                  icon={
                    <Icon
                      as={YoutubeLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                      color={"white"}
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />
              </EditableContent>
            </HStack>

            <Box w={"100%"} h={"1px"} bg={"var(--divider)"} my={8} />

            <EditableContent kontenId={data.copyRight.id}>
              <Text>{data.copyRight.konten}</Text>
            </EditableContent>

            <EditableContent kontenId={data.policyLink.id}>
              <Text as={ChakraLink} isExternal href={data.policyLink.konten}>
                Privacy Terms & Conditions
              </Text>
            </EditableContent>
          </Container>
        </VStack>
      </VStack>
    </VStack>
  );
}
