import {
  HStack,
  Image,
  Link as ChakraLink,
  Text,
  Box,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import landingNav from "../../const/landingNav";
import useScreenWidth from "../../lib/useGetScreenWidth";
import LandingNavDrawer from "./LandingNavDrawer";
import { LandingNavSection } from "../../const/types";
import EditableContent from "./EditableContent";

type Props = {
  data: LandingNavSection;
};

export default function LandingNav({ data }: Props) {
  const sw = useScreenWidth();

  return (
    <Box
      w={"100%"}
      position={"sticky"}
      top={"50px"}
      zIndex={96}
      backdropFilter={"blur(5px)"}
      bg={useColorModeValue("whiteAlpha.800", "blackAlpha.800")}
    >
      <HStack
        w={"100%"}
        maxW={"1200px"}
        mx={"auto"}
        py={4}
        px={[4, 6, 8]}
        gap={8}
        justify={"space-between"}
      >
        <HStack gap={8}>
          <HStack>
            <Image src="/logo.png" h={"20px"} />
            <Text fontWeight={800} fontSize={[16, null, 18]}>
              Kubu.id
            </Text>
          </HStack>

          {sw >= 700 && (
            <>
              <Box w={"50px"} h={"2px"} bg={"p.500"} />

              <HStack gap={1}>
                {landingNav.map((nav, i) => (
                  <Button
                    key={i}
                    as={ChakraLink}
                    href={nav.link}
                    fontWeight={600}
                    variant={"ghost"}
                    colorScheme="ap"
                    _hover={{ bg: "p.500", color: "white" }}
                  >
                    {nav.name}
                  </Button>
                ))}
              </HStack>
            </>
          )}
        </HStack>

        <HStack>
          {sw < 700 && <LandingNavDrawer />}

          <EditableContent kontenId={data.googlePlayLink.id}>
            <Button variant={"outline"} colorScheme="ap">
              Download
            </Button>
          </EditableContent>
        </HStack>
      </HStack>
    </Box>
  );
}
