import React from "react";
import { VideoSection } from "../const/types";
import { Box, Icon, VStack } from "@chakra-ui/react";
import Container from "../components/landingPageComponents/Container";
import { Gear } from "@phosphor-icons/react";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: VideoSection;
};

export default function Video({ data }: Props) {
  return (
    <VStack gap={0} align={"flex-start"} w={"100%"} py={12}>
      <Box
        bg={"linear-gradient(to top right, var(--p700), var(--p500))"}
        w={"calc(100% - 60px)"}
        h={["200px", "300px", "400px", "500px"]}
        position={"absolute"}
        mt={-20}
        overflow={"hidden"}
      >
        <Icon
          as={Gear}
          fontSize={350}
          position={"absolute"}
          left={"-100px"}
          top={"-60px"}
          weight="duotone"
          color={"var(--divider)"}
        />
        <Icon
          as={Gear}
          fontSize={400}
          position={"absolute"}
          right={"-150px"}
          bottom={"-10px"}
          weight="duotone"
          color={"var(--divider)"}
        />
      </Box>

      <Container position={"relative"}>
        <EditableContent kontenId={55}>
          <VStack className="video-responsive">
            <iframe
              width="853"
              height="480"
              src={data.youtubeLink.konten}
              title="Introduction to KUBU  | Application for Indonesian Workers and Online Community"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </VStack>
        </EditableContent>
      </Container>
    </VStack>
  );
}
