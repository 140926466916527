import { ChakraProvider } from "@chakra-ui/react";
import { globalTheme } from "./chakraTheme/globalTheme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CmsLandingPage from "./pages/CmsLandingPage";
import "./globalStyle.css";
import MissingPage from "./pages/MissingPage";
import Dashboard from "./pages/Dashboard";
import Signin from "./pages/Signin";
import Home from "./pages/Home";
import RequireAuth from "./middleware/RequireAuth";
import Blog from "./pages/Blog";

export const App = () => (
  <ChakraProvider theme={globalTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />

        <Route
          path="/home"
          element={
            <RequireAuth allowedRoles="">
              <Home />
            </RequireAuth>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RequireAuth allowedRoles={"adminDashboard"}>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route
          path="/cms-kubuid-landingpage"
          element={
            <RequireAuth allowedRoles={"adminCmsLandingpage"}>
              <CmsLandingPage />
            </RequireAuth>
          }
        />

        <Route
          path="/blog"
          element={
            <RequireAuth allowedRoles={"adminBlog"}>
              <Blog />
            </RequireAuth>
          }
        />

        <Route path="*" element={<MissingPage />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);
