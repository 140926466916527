import { extendTheme } from "@chakra-ui/react";

export const globalTheme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    p: {
      50: "#CCEFFF",
      100: "#CDE9FB",
      200: "#9DCFF8",
      300: "#6AACEB",
      400: "#4489D8",
      500: "#115ABE",
      600: "#0C45A3",
      700: "#083388",
      800: "#05246E",
      900: "#03195B",
    },
    ap: {
      50: "#115ABE1b",
      100: "#115ABE2b",
      200: "#115ABE",
      300: "#115ABE",
      400: "#115ABE",
      500: "#115ABE",
      600: "#115ABE",
      700: "#115ABE",
      800: "#115ABE",
      900: "#115ABE",
    },
    bnw: {
      200: "white",
      300: "white",
      500: "#000000",
      600: "#000000",
    },
    wnb: {
      200: "#000000",
      300: "#000000",
      500: "white",
      600: "white",
    },
    b: "#000000",
    bt: "#333333",
    w: "white",
    wt: "#eeeeee",
    error: "#E53E3E",
    divider: "#b4b4b420",
    divider2: "#b4b4b440",
    divider3: "#b4b4b450",
  },

  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? "#111111" : "white",
        color: props.colorMode === "dark" ? "wt" : "bt",
      },
    }),
  },

  components: {
    Badge: {
      baseStyle: (props) => ({
        p: "4px 16px",
        borderRadius: 8,
        textTransform: "none",
      }),
    },
    Drawer: {
      baseStyle: (props) => ({
        dialog: {
          bg: "transparent",
          color: props.colorMode === "dark" ? "wt" : "wt",
          boxShadow: "none",
        },
      }),
    },

    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: props.colorMode === "dark" ? "dark" : "white",
          color: props.colorMode === "dark" ? "wt" : "bt",
          boxShadow: "none",
          borderRadius: 16,
          mx: "16px",
          backdropFilter: "blur(20px)",
        },
        overlay: {
          bg: "#5b5b5b50",
          backdropFilter: "blur(5px)",
        },
        header: {
          py: "20px",
          px: "24px",
          pr: "70px !important",
        },
        body: {
          px: "24px",
          py: "0px !important",
        },
        footer: {
          p: "24px",
        },
        closeButton: {
          borderRadius: 8,
          right: 4,
          top: 4,
          fontSize: "13px !important",
        },
      }),
    },

    Toast: {
      baseStyle: {
        fontSize: [13, null, 15],
      },
    },

    Menu: {
      baseStyle: (props) => ({
        groupTitle: {
          opacity: 0.5,
          cursor: "default",
        },
        divider: {
          my: 0,
        },
        list: {
          bg: props.colorMode === "dark" ? "dark" : "white",
          border: "1px solid var(--divider3)",
          p: 0,
          overflow: "hidden",
          boxShadow: "none",
          borderRadius: "8px",
        },
        item: {
          bg: "transparent",
          _hover: { bg: "var(--divider)" },
          py: 3,
          px: 4,
        },
      }),
    },

    Button: {
      baseStyle: {
        fontWeight: 500,
        borderRadius: "8px",
        // borderRaddius: "full",
      },
      variants: {
        outline: {
          // border: "2px solid",
        },
      },
    },

    Input: {
      baseStyle: (props) => ({
        field: {
          _autofill: {
            boxShadow:
              props.colorMode === "dark"
                ? "0 0 0px 1000px dark inset"
                : "0 0 0px 1000px #ffffff inset",
            border: "2px solid var(--divider) !important",
          },
        },
      }),
    },

    Checkbox: {
      baseStyle: (props) => ({
        icon: {
          color: "white",
        },
        control: {
          border: props.isInvalid
            ? "1.5px solid #E53E3E"
            : "2px solid var(--divider3) !important",
        },
      }),
    },

    Tooltip: {
      baseStyle: {
        bg: "black",
        color: "w",
        "--popper-arrow-bg": "#000000",
        borderRadius: 8,
        px: 4,
        py: 2,
      },
    },

    Table: {
      sizes: {
        md: {
          th: {
            py: "16px",
            px: "12px",
          },
          td: {
            py: "16px",
            px: "12px",
          },
        },
      },
    },
  },
});
