import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import Container from "../components/landingPageComponents/Container";
import { FiturSection } from "../const/types";
import FiturItemModal from "../components/landingPageComponents/FiturItemModal";
import EditableContent from "../components/landingPageComponents/EditableContent";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";

type Props = {
  data: FiturSection;
};

export default function Fitur({ data }: Props) {
  const addon = useRef<HTMLDivElement>(null);

  return (
    <VStack id="fiturSection" py={20} align={"flex-start"} bg={"var(--p500a)"}>
      <Container>
        <VStack mb={12}>
          <EditableContent kontenId={data.judul.id}>
            <Text
              textAlign={"center"}
              fontSize={[32, null, 34]}
              fontWeight={800}
            >
              {data.judul.konten}
            </Text>
          </EditableContent>
        </VStack>

        <SimpleGrid columns={[1, null, 2]} gap={8} mb={8}>
          {data.fiturItems?.map((fitur, i) => (
            <Box key={i} mb={4}>
              <HStack align={"flex-start"} gap={[4, 5, 6]}>
                <EditableContent kontenId={fitur.icon.id}>
                  <Center
                    borderRadius={"full"}
                    p={2}
                    bg={"var(--p100)"}
                    flexShrink={0}
                    w={"40px"}
                    h={"40px"}
                    mt={-1}
                  >
                    <Image
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${fitur.icon.konten}`}
                      h={"20px"}
                      flexShrink={0}
                    />
                  </Center>
                </EditableContent>

                <Box pb={4}>
                  <EditableContent kontenId={fitur.judul.id}>
                    <Text fontWeight={800} fontSize={[18, null, 20]} mb={2}>
                      {fitur.judul.konten}
                    </Text>
                  </EditableContent>

                  <EditableContent kontenId={fitur.narasi.id}>
                    <Text lineHeight={1.8} opacity={0.5} noOfLines={3} mb={8}>
                      {fitur.narasi.konten}
                    </Text>
                  </EditableContent>

                  <FiturItemModal data={fitur} />
                </Box>
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      <Box
        ref={addon}
        className="noScroll"
        w={"100%"}
        px={[4, 6, 8]}
        overflow={"auto"}
        scrollSnapType={"x mandatory"}
      >
        <HStack
          // animation={"infiniteScrollX 30s linear infinite"}

          w={"max-content"}
          gap={6}
          py={8}
          mx={"auto"}
          // pr={6}
          align={"stretch"}
        >
          {data.addon.map((addon, i) => (
            <HStack
              key={i}
              gap={6}
              scrollSnapAlign={"center"}
              border={"2px solid var(--p400)"}
              borderRadius={8}
              p={4}
              w={"max-content"}
              justify={"center"}
            >
              <EditableContent kontenId={addon.img.id}>
                <Image
                  src={`${process.env.REACT_APP_MEDIA_DOMAIN}${addon.img.konten}`}
                  w={"30px"}
                />
              </EditableContent>

              <EditableContent kontenId={addon.narasi.id}>
                <Text
                  fontSize={16}
                  fontWeight={600}
                  maxW={"240px"}
                  lineHeight={1.2}
                  color={"p.400"}
                >
                  {addon.narasi.konten}
                </Text>
              </EditableContent>
            </HStack>
          ))}
        </HStack>
      </Box>

      <HStack w={"100%"} justify={"center"}>
        <IconButton
          aria-label="prev"
          icon={<Icon as={ArrowLeft} fontSize={iconSize} />}
          colorScheme="ap"
          variant={"outline"}
          onClick={() => {
            if (addon.current) {
              addon.current.scrollLeft += -200;
            }
          }}
        />
        <IconButton
          aria-label="next"
          icon={<Icon as={ArrowRight} fontSize={iconSize} />}
          colorScheme="ap"
          variant={"outline"}
          onClick={() => {
            if (addon.current) {
              addon.current.scrollLeft += 200;
            }
          }}
        />
      </HStack>
    </VStack>
  );
}
