import React, { useCallback, useEffect } from "react";
import { Content } from "../../const/types";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControl, FormLabel, useToast } from "@chakra-ui/react";
import cr from "../../lib/request";
import useTrigger from "../../globalState/useTrigger";
import ImageUploader from "./ImageUploader";

interface Props {
  initialFocusRef: any;
  initialData: Content;
  setUpdateLoading: (loading: boolean) => void;
  onClose: () => void;
}

export default function UpdateFormImage({
  initialFocusRef,
  initialData,
  setUpdateLoading,
  onClose,
}: Props) {
  useEffect(() => {
    if (initialFocusRef && initialFocusRef.current) {
      initialFocusRef.current.focus();
    }
  }, [initialFocusRef]);

  const autoResize = useCallback(() => {
    if (initialFocusRef.current) {
      initialFocusRef.current.style.height = "auto";
      initialFocusRef.current.style.height = `${
        initialFocusRef.current.scrollHeight + 5
      }px`;
    }
  }, [initialFocusRef]);

  useEffect(() => {
    autoResize();
  }, [autoResize]);

  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      konten: [] as File[] | null,
    },

    validationSchema: yup.object().shape({
      konten: yup
        .array()
        .min(1, "Konten harus diunggah")
        .required("Konten harus diunggah")
        .test(
          "fileType",
          "Hanya file JPG, PNG, HEIC, SVG yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "image/svg+xml" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/HEIC"
            )
        ),
      // .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
      //   value.every((file) => file.size <= 1000000)
      // ),
    }),
    onSubmit: (values, { resetForm }) => {
      setUpdateLoading(true);
      const url = `api/content/${initialData.content_data.id}/update`;
      const payload = new FormData();
      if (values.konten) {
        values.konten.forEach((file) => {
          payload.append("content", file);
        });
      }

      cr.post(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            onClose();
            toast({
              title: "Update Konten Berhasil",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setTrigger(!trigger);
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Update Konten Gagal",
            description: "Maaf terjadi kesalahan",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setUpdateLoading(false);
        });
    },
  });

  return (
    <form id={"updateForm"} onSubmit={formik.handleSubmit}>
      <FormControl mb={4} isInvalid={formik.errors.konten ? true : false}>
        <FormLabel>Isi Konten</FormLabel>
        <ImageUploader
          name={"konten"}
          formik={formik}
          prevSrc={initialData?.content_data?.content}
        />
      </FormControl>
    </form>
  );
}
