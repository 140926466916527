import React from "react";
import AdminContainer from "../components/AdminContainer";
import NavHeader from "../components/NavHeader";
import { HStack, Heading, Icon, SimpleGrid, VStack } from "@chakra-ui/react";
import { PencilSimpleLine, Plus, Trash } from "@phosphor-icons/react";
import BlogMenuItem from "../components/blogComponents/BlogMenuItem";

export default function Blog() {
  return (
    <AdminContainer active={[4]}>
      <NavHeader label="Blog" />

      <VStack
        flex={1}
        align={"stretch"}
        //   justify={"center"}
        p={5}
      >
        <SimpleGrid columns={[1, 2, 3]} color={"white"} gap={5}>
          <BlogMenuItem
            border={"2px solid var(--p500)"}
            color={"p.500"}
            _hover={{ bg: "p.500", color: "white" }}
          >
            <HStack>
              <Icon as={Plus} fontSize={50} />
              {/* <Icon as={BookOpenText} fontSize={50} /> */}
            </HStack>

            <Heading size={"md"}>CREATE</Heading>
          </BlogMenuItem>

          <BlogMenuItem
            border={"2px solid #805AD5"}
            color={"purple.500"}
            _hover={{ bg: "purple.500", color: "white" }}
          >
            <HStack>
              <Icon as={PencilSimpleLine} fontSize={50} />
              {/* <Icon as={BookOpenText} fontSize={50} /> */}
            </HStack>

            <Heading size={"md"}>UPDATE</Heading>
          </BlogMenuItem>

          <BlogMenuItem
            border={"2px solid #E53E3E"}
            color={"red.500"}
            _hover={{ bg: "red.500", color: "white" }}
          >
            <HStack>
              <Icon as={Trash} fontSize={50} />
              {/* <Icon as={BookOpenText} fontSize={50} /> */}
            </HStack>

            <Heading size={"md"}>DELETE</Heading>
          </BlogMenuItem>
        </SimpleGrid>
      </VStack>
    </AdminContainer>
  );
}
