import {
  Box,
  Center,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Container from "../components/landingPageComponents/Container";
import { BlogSection } from "../const/types";
import { ArrowUpRight } from "@phosphor-icons/react";
import EditableContent from "../components/landingPageComponents/EditableContent";

type Props = {
  data: BlogSection;
};

export default function Blog({ data }: Props) {
  const contents = [
    {
      link: "/soon",
      img: "/images/blog/1.jpg",
      judul: "Cara menulis surat cinta yang bisa membuat dia gila",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
      link: "/soon",
      img: "/images/blog/2.jpg",
      judul: "7 buku wajib yang harus dimiliki anak IT",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
      link: "/soon",
      img: "/images/blog/3.jpg",
      judul: "Ketahui trik ini agar kalian tau cara bernafas dengan benar",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  ];
  return (
    <VStack id="blogSection" py={20} align={"flex-start"}>
      <Container>
        <VStack mb={12}>
          <EditableContent kontenId={data.judul.id}>
            <Text
              textAlign={"center"}
              fontSize={[32, null, 34]}
              fontWeight={800}
            >
              {data.judul.konten}
            </Text>
          </EditableContent>
        </VStack>

        <SimpleGrid columns={[1, 2, 3]} gap={4}>
          {contents.map((content, i) => (
            <Box
              key={i}
              _hover={{
                boxShadow: "0 0 0 2px var(--p400)",
                transform: "scale(1.03)",
              }}
              borderRadius={8}
              p={2}
              transition={"200ms"}
            >
              <Box
                bgImage={content.img}
                bgSize={"cover"}
                bgPos={"center"}
                h={"200px"}
                borderRadius={8}
                mb={4}
              />

              <Box p={2}>
                <Text
                  fontSize={[20, null, 22]}
                  fontWeight={700}
                  noOfLines={2}
                  mb={4}
                  lineHeight={1.4}
                >
                  {content.judul}
                </Text>

                <Text noOfLines={3} mb={4}>
                  {content.desc}
                </Text>

                <Text color={"p.500"}>Baca selengkapnya</Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>

        <HStack mt={20} justify={"flex-end"}>
          <EditableContent kontenId={data.linkAllBlog.id}>
            <HStack
              borderRadius={"full"}
              justify={"space-between"}
              color={"white"}
              gap={4}
              className="lg-clicky"
              transition={"200ms"}
              cursor={"pointer"}
              bg={"linear-gradient(to top right, var(--p700), var(--p500))"}
              w={"max-content"}
              alignSelf={"flex-end"}
              p={2}
              as={Link}
              isExternal
              href={data.linkAllBlog.konten}
            >
              <Text
                fontWeight={600}
                fontSize={[18, null, 20]}
                textAlign={"center"}
                color={"white"}
                px={6}
              >
                Jelajahi Semua Blog Kubu.id
              </Text>

              <Center bg={"white"} borderRadius={"full"} p={2}>
                <Icon
                  as={ArrowUpRight}
                  fontSize={[28, null, 30]}
                  weight="bold"
                  color={"p.500"}
                />
              </Center>
            </HStack>
          </EditableContent>
        </HStack>
      </Container>
    </VStack>
  );
}
