import {
  Box,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import adminNav from "../const/adminNav";
import { Link } from "react-router-dom";
import { Cookies } from "typescript-cookie";
import SignoutModal from "./SignoutModal";
import { User } from "@phosphor-icons/react";

export default function SideNav(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userCookie = Cookies.get("user");
  const user = JSON.parse(userCookie as string);

  return (
    <>
      <Box
        display={isOpen ? "block" : "none"}
        h={"100vh"}
        w={"100%"}
        position={"fixed"}
        top={0}
        left={0}
        bg={"#5b5b5b50"}
        backdropFilter={"blur(5px)"}
        zIndex={98}
        onMouseEnter={onClose}
        onClick={onClose}
      />

      <VStack
        bg={"black"}
        color={"white"}
        gap={6}
        flexShrink={0}
        w={isOpen ? "300px" : "60px"}
        onMouseEnter={onOpen}
        py={4}
        h={"100vh"}
        overflowX={"hidden"}
        position={"fixed"}
        left={0}
        top={0}
        align={"stretch"}
        justify={"space-between"}
        zIndex={99}
        transition={"200ms"}
        // borderRight={"1px solid var(--divider3)"}
      >
        <Box>
          <HStack justify={"space-between"} px={4} mb={8}>
            <HStack gap={2}>
              <Image src="/logo.png" w={"26px"} borderRadius={"full"} />
              {isOpen && (
                <Text fontWeight={800} noOfLines={1}>
                  Kubu.id
                </Text>
              )}
            </HStack>

            {isOpen && (
              <HStack>
                <Icon as={User} />

                <Text noOfLines={1} fontWeight={700}>
                  {user.username}
                </Text>
              </HStack>
            )}
          </HStack>

          <Box w={"100%"}>
            {adminNav.map((nav, i) => {
              const isActive = props.active.includes(nav.id);
              const isValidRole = user.roles[nav.role];

              if (isValidRole || nav.role === "") {
                return (
                  <HStack
                    key={i}
                    h={"56px"}
                    as={Link}
                    to={nav.link}
                    gap={4}
                    _hover={{ bg: "var(--divider)" }}
                    py={4}
                    pr={4}
                    pl={5}
                    bg={isActive ? "p.500" : ""}
                  >
                    <Icon as={nav.icon} weight={"fill"} fontSize={20} />
                    {isOpen && (
                      <Text fontWeight={600} noOfLines={1}>
                        {nav.name}
                      </Text>
                    )}
                  </HStack>
                );
              } else {
                return null;
              }
            })}
          </Box>
        </Box>

        <Box w={"100%"}>
          <SignoutModal isOpenNav={isOpen} />
        </Box>
      </VStack>
    </>
  );
}
