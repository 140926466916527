import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { SignOut } from "@phosphor-icons/react";
import React, { useState } from "react";
import cr from "../lib/request";
import { useNavigate } from "react-router-dom";

type Props = {
  isOpenNav: boolean;
};

export default function SignoutModal({ isOpenNav }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onSignout = () => {
    setLoading(true);
    const url = `api/logout`;
    cr.post(url)
      .then((r) => {
        navigate("/");
        toast({
          title: "Berhasil Keluar",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        w={"100%"}
        borderRadius={0}
        leftIcon={<Icon as={SignOut} fontSize={18} />}
        pr={isOpenNav ? 4 : 1}
        className="btn-clear"
        color={"white"}
        onClick={onOpen}
      >
        <Text noOfLines={1}>{isOpenNav ? "Sign Out" : ""}</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Signing Out</ModalHeader>
          <ModalBody>Apakah anda yakin untuk keluar?</ModalBody>
          <ModalFooter gap={2}>
            <Button className="btn" onClick={onSignout} isLoading={loading}>
              Sign Out
            </Button>
            <Button colorScheme="ap" onClick={onClose}>
              Nope
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
