import React from "react";
import {
  Badge,
  Box,
  Center,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import AdminContainer from "../components/AdminContainer";
import NavHeader from "../components/NavHeader";
import { Cookies } from "typescript-cookie";
import formatDate from "../lib/formatDate";
import { IdentificationCard, User } from "@phosphor-icons/react";

export default function Home() {
  const userCookie = Cookies.get("user");
  const user = JSON.parse(userCookie as string);

  return (
    <AdminContainer active={[1]}>
      <NavHeader label="Home" />

      <SimpleGrid columns={[1, null, 2, null, 3]} gap={5} p={5}>
        <VStack align={"strech"}>
          <VStack className="homeItemContainer" px={4}>
            {user.image ? (
              <Center className="profileImageContainer">
                <Image src={user.image} />
              </Center>
            ) : (
              <Center className="profileImageContainer">
                <Icon as={User} fontSize={150} />
              </Center>
            )}
            <VStack px={2}>
              <Heading textAlign={"center"} color={"p.500"} size="lg">
                {user.name}
              </Heading>
              <Text textAlign={"center"}>{user.email || "-"}</Text>
            </VStack>
          </VStack>
        </VStack>

        <VStack align={"strech"} gap={6}>
          <VStack className="homeItemContainer">
            <Heading as="h3" size={"md"} mb={2}>
              Detail Akun
            </Heading>

            <HStack gap={4}>
              <Text className="profileLabel">ID</Text>
              <Text>:</Text>
              <Text>{user.id}</Text>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Username</Text>
              <Text>:</Text>
              <Text>{user.username}</Text>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Nama</Text>
              <Text>:</Text>
              <Text>{user.name || "-"}</Text>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Email</Text>
              <Text>:</Text>
              <Text>{user.email || "-"}</Text>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Telepon</Text>
              <Text>:</Text>
              <Text>{user.phone || "-"}</Text>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Dibuat</Text>
              <Text>:</Text>
              <Text>{formatDate(user.created_at)}</Text>
            </HStack>
          </VStack>
        </VStack>

        <VStack align={"stretch"}>
          <VStack className="homeItemContainer">
            <Heading as="h3" size={"md"} mb={2}>
              Akses Admin
            </Heading>

            <VStack
              align={"stretch"}
              borderRadius={8}
              p={6}
              bg={"linear-gradient(to bottom right, var(--p500), var(--p300))"}
              color={"white"}
              maxW={"360px"}
              gap={6}
              mb={4}
            >
              <HStack justify={"space-between"}>
                <Image src={"/logo.png"} h={"40px"} borderRadius={"full"} />

                <Text
                  fontSize={20}
                  fontWeight={600}
                  fontStyle={"italic"}
                  color={"yellow.300"}
                >
                  ADMIN
                </Text>
              </HStack>

              <HStack gap={4}>
                {user.image ? (
                  <Center>
                    <Image src={user.image} />
                  </Center>
                ) : (
                  <Center>
                    <Icon as={User} fontSize={40} />
                  </Center>
                )}

                <Box>
                  <Text noOfLines={1}>{user.name}</Text>
                  <Text noOfLines={1} opacity={0.5}>
                    {user.email}
                  </Text>
                </Box>
              </HStack>

              <HStack justify={"space-between"} align={"flex-end"}>
                <Text>{user.username}</Text>
                <Box>
                  <Text opacity={0.5} fontSize={12}>
                    Created
                  </Text>
                  <Text>
                    {formatDate(user.created_at, {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })}
                  </Text>
                </Box>
              </HStack>
            </VStack>

            <HStack gap={4}>
              <Text className="profileLabel">CMS Landing Page</Text>
              <Text>:</Text>
              <Badge
                colorScheme={user.roles.adminCmsLandingpage ? "green" : "red"}
                className="badge"
              >
                {user.roles.adminCmsLandingpage ? "Ya" : "Tidak"}
              </Badge>
            </HStack>

            <HStack gap={4}>
              <Text className="profileLabel">Blog</Text>
              <Text>:</Text>
              <Badge
                colorScheme={user.roles.adminBlog ? "green" : "red"}
                className="badge"
              >
                {user.roles.adminBlog ? "Ya" : "Tidak"}
              </Badge>
            </HStack>
          </VStack>
        </VStack>
      </SimpleGrid>
    </AdminContainer>
  );
}
