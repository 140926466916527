import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import cr from "../lib/request";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "typescript-cookie";

export default function Signin() {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: yup.object().shape({
      username: yup.string().required("Username harus diisi"),
      password: yup.string().required("Password harus diisi"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const url = `api/login`;
      const payload = values;
      cr.post(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            Cookies.set("user", JSON.stringify(response.data.data));
            navigate("/home");
            toast({
              title: response.data.message,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Something wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data.error || "Something Wrong",
            description:
              error.response.data.message || "Maaf terjadi kesalahan",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  return (
    <VStack
      justify={"center"}
      p={8}
      h={"100vh"}
      bg={"linear-gradient(to bottom, var(--p500), var(--p700))"}
    >
      <VStack
        borderRadius={8}
        p={8}
        boxShadow={"0 0 5px 5px var(--divider3)"}
        align={"stretch"}
        w={"100%"}
        maxW={"400px"}
        bg={"white"}
      >
        <HStack mb={4} gap={4}>
          <Image src="/logo.png" w={"50px"} borderRadius={"full"} />
          <Box>
            <Text
              fontWeight={700}
              lineHeight={1.4}
              fontSize={20}
              color={"p.500"}
            >
              Welcome To
            </Text>
            <Text
              fontWeight={700}
              lineHeight={1.4}
              fontSize={20}
              color={"p.500"}
            >
              ADMIN KUBU
            </Text>
          </Box>
        </HStack>

        <form id="signinForm" onSubmit={formik.handleSubmit}>
          <FormControl mb={4} isInvalid={formik.errors.username ? true : false}>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              placeholder="Enter username"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
          </FormControl>

          <FormControl mb={4} isInvalid={formik.errors.password ? true : false}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                pr="46px"
                type={showPass ? "text" : "password"}
                placeholder="Enter password"
              />
              <InputRightElement>
                <IconButton
                  aria-label="showPass"
                  className="btn-clear"
                  icon={<Icon as={!showPass ? Eye : EyeSlash} fontSize={18} />}
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                >
                  {showPass ? "Hide" : "Show"}
                </IconButton>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            form="signinForm"
            w={"100%"}
            colorScheme="ap"
            className="lg-clicky"
            mt={4}
            isLoading={loading}
          >
            Sign In
          </Button>
        </form>
      </VStack>
    </VStack>
  );
}
