import {
  BookOpenText,
  GlobeSimple,
  House,
  Layout,
  Student,
} from "@phosphor-icons/react";

const adminNav = [
  {
    id: 1,
    name: "Home",
    icon: House,
    link: "/home",
    role: "",
  },
  {
    id: 2,
    name: "Dashboard",
    icon: Layout,
    link: "/dashboard",
    role: "adminDashboard",
  },
  {
    id: 3,
    name: "CMS Landing Page",
    icon: GlobeSimple,
    link: "/cms-kubuid-landingpage",
    role: "adminCmsLandingpage",
  },
  {
    id: 4,
    name: "Blog",
    icon: BookOpenText,
    link: "/blog",
    role: "adminBlog",
  },
  {
    id: 5,
    name: "Course",
    icon: Student,
    link: "/course",
    role: "adminCourse",
  },
];

export default adminNav;
