import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
  Link as ChakraLink,
  Box,
} from "@chakra-ui/react";
import { List, X } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../../const/sizes";
import landingNav from "../../const/landingNav";

export default function LandingNavDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    // window.history.back();
  };

  window.addEventListener("onbeforeunload ", () => {
    console.log("User clicked back button");
  });

  return (
    <>
      <IconButton
        aria-label="drawerButton"
        icon={<Icon as={List} fontSize={20} />}
        onClick={onOpen}
        variant={"ghost"}
        colorScheme="ap"
      />

      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />

        <DrawerContent>
          <IconButton
            aria-label="close nav drawer"
            icon={<Icon as={X} fontSize={iconSize} />}
            borderRadius={"full"}
            position={"absolute"}
            left={-5}
            top={"calc(50% - 20px)"}
            zIndex={99}
            bg={"white"}
            color={"black"}
            _hover={{ bg: "white" }}
            _active={{ bg: "white" }}
            className="clicky"
            onClick={handleOnClose}
          />

          <DrawerBody
            as={VStack}
            align={"stretch"}
            p={0}
            position={"relative"}
            bg={"p.500"}
            py={5}
            overflow={"hidden"}
          >
            <Image
              src={"/images/logoTransparent.png"}
              w={"100%"}
              position={"absolute"}
              bottom={-20}
              right={-20}
              opacity={0.1}
            />

            <VStack gap={0} position={"relative"}>
              {/* <ColorModeSwitcher position={"absolute"} left={0} top={-3} /> */}

              <Image src="/logo.png" w={"48px"} mb={2} />

              <Text fontWeight={700} lineHeight={1}>
                Kubu.id
              </Text>
            </VStack>

            <VStack
              w={"100%"}
              flex={1}
              py={6}
              px={10}
              align={"stretch"}
              justify={"space-between"}
            >
              <VStack w={"100%"} align={"stretch"}>
                <Text
                  fontWeight={500}
                  opacity={0.5}
                  ml={4}
                  fontSize={[12, null, 14]}
                >
                  NAVIGASI
                </Text>
                {landingNav.map((nav, i) => (
                  <ChakraLink key={i} href={nav.link}>
                    <Box
                      key={i}
                      py={2}
                      px={4}
                      borderRadius={8}
                      _hover={{ bg: "var(--divider)" }}
                    >
                      <Text fontWeight={500} fontSize={18}>
                        {nav.name}
                      </Text>
                    </Box>
                  </ChakraLink>
                ))}
              </VStack>

              <VStack align={"stretch"}>
                {/* <SigninModal />

                <SignupModal /> */}
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
