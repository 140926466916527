import React, { useRef } from "react";
import { FiturItem } from "../../const/types";
import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import useBackOnClose from "../../lib/useBackOnClose";
import EditableContent from "./EditableContent";

type Props = {
  data: FiturItem;
};

export default function FiturItemModal({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    window.history.back();
    onClose();
  };
  const modalContentRef = useRef(null);

  return (
    <>
      <Text
        color={"p.500"}
        cursor={"pointer"}
        onClick={onOpen}
        w={"max-content"}
        _hover={{ opacity: 0.5 }}
        transition={"200ms"}
      >
        Lihat selengkapnya
      </Text>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        initialFocusRef={modalContentRef}
        scrollBehavior="inside"
        size={"xl"}
        isCentered
      >
        <ModalOverlay />

        <ModalContent ref={modalContentRef}>
          <ModalBody pt={6}>
            <Stack flexDir={["column", null, "row"]} gap={8} align={"center"}>
              <VStack w={"200px"} flexShrink={0}>
                <EditableContent kontenId={data.img.id}>
                  <Image
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${data.img.konten}`}
                  />
                </EditableContent>
              </VStack>

              <Box>
                <EditableContent kontenId={data.icon.id}>
                  <Center
                    borderRadius={"full"}
                    p={2}
                    bg={"var(--p100)"}
                    flexShrink={0}
                    w={"40px"}
                    h={"40px"}
                    mt={-1}
                    mb={4}
                  >
                    <Image
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${data.icon.konten}`}
                      w={"20px"}
                      flexShrink={0}
                    />
                  </Center>
                </EditableContent>

                <EditableContent kontenId={data.judul.id}>
                  <Text fontWeight={800} fontSize={[24, null, 26]} mb={4}>
                    {data.judul.konten}
                  </Text>
                </EditableContent>

                <EditableContent kontenId={data.narasi.id}>
                  <Text lineHeight={1.8}>{data.narasi.konten}</Text>
                </EditableContent>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button w={"100%"} className="btn-solid" onClick={handleOnClose}>
              Tutup
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
