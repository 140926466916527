import React, { useCallback, useEffect, useState } from "react";
import KubuSpinner from "../components/KubuSpinner";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import cr from "../lib/request";
import { Cookies } from "typescript-cookie";

type Props = {
  children: JSX.Element;
  allowedRoles: string;
};

export default function RequireAuth({ children, allowedRoles }: Props) {
  const [page, setPage] = useState(<KubuSpinner />);

  const navigate = useNavigate();
  const toast = useToast();

  const onSignout = useCallback(() => {
    const url = `api/logout`;
    cr.post(url)
      .then((r) => {
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  }, [navigate]);

  useEffect(() => {
    setPage(<KubuSpinner />);

    const url = "api/logindata";
    cr.get(url)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.roles[allowedRoles] || allowedRoles === "") {
            Cookies.set("user", JSON.stringify(response.data.data));
            setPage(children);
          } else {
            onSignout();
            toast({
              title: "Tidak Ada Akses",
              description: "Anda tidak terdaftar untuk autentikasi ini",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Tidak Ada Akses",
          description: "Silahkan masuk terlebih dahulu",
          status: "error",
          duration: 10000,
          isClosable: true,
        });
        navigate("/");
      });
  }, [children, navigate, toast, allowedRoles, onSignout]);

  return page;
}
