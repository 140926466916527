import {
  Box,
  Button,
  FormErrorMessage,
  HStack,
  Image,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { ChangeEvent, useRef, useState } from "react";

type Props = {
  prevSrc: string;
  formik: any;
  name: string;
};

const ImageUploader = ({ prevSrc, formik, name }: Props) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }

    const files: FileList | null = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      formik.setFieldValue(name, [file]);
    }
    // console.log(files);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Input
        ref={inputRef}
        display={"none"}
        name={name}
        type="file"
        accept=".jpg, .jpeg, .png, .HEIC, .svg"
        onChange={handleImageChange}
        mb={4}
      />

      <Button
        px={0}
        w={"100%"}
        fontWeight={400}
        variant={"ghost"}
        className="btn"
        gap={0}
        border={"1px solid var(--divider3)"}
        boxShadow={formik.errors[name] ? "0 0 0px 1px var(--red)" : ""}
        borderRadius={8}
        cursor={"pointer"}
        _focus={{ boxShadow: "0 0 0px 2px var(--p500)" }}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        <HStack gap={0} w={"100%"}>
          <HStack
            h={"40px"}
            flexShrink={0}
            px={4}
            py={2}
            borderRadius={"8px 0 0 8px"}
            bg={"var(--divider3)"}
          >
            <Text fontSize={[12, null, 14]}>Pilih File</Text>
          </HStack>

          <Box px={4} py={2}>
            <Tooltip label={formik?.values?.konten[0]?.name}>
              <Text
                noOfLines={1}
                fontSize={[12, null, 14]}
                whiteSpace={"normal"}
                textAlign={"left"}
              >
                {formik?.values?.konten[0]?.name ||
                  "Pilih gambar yang ingin diunggah"}
              </Text>
            </Tooltip>
          </Box>
        </HStack>
      </Button>

      <FormErrorMessage>{formik.errors.konten}</FormErrorMessage>
      {!previewUrl && (
        <Text mt={1} fontSize={"small"}>
          *preview dibawah adalah gambar sebelumnya
        </Text>
      )}

      <Text fontStyle={"italic"} mb={2} mt={4} fontWeight={500}>
        Preview
      </Text>
      <Box
        p={"16px"}
        bg={"var(--divider4)"}
        border={"1px solid var(--divider2)"}
        borderRadius={8}
      >
        <Image
          src={
            previewUrl
              ? previewUrl
              : `${process.env.REACT_APP_MEDIA_DOMAIN}${prevSrc}`
          }
          alt="Preview"
          w={"100%"}
        />
      </Box>
    </>
  );
};

export default ImageUploader;
