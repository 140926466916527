import { Center, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Hero from "../landingSections/Hero";
import Layanan from "../landingSections/Layanan";
import Statistik from "../landingSections/Statistik";
import Fitur from "../landingSections/Fitur";
import LandingNav from "../components/landingPageComponents/LandingNav";
import { LandingData } from "../const/types";
import Footer from "../landingSections/Footer";
import Blog from "../landingSections/Blog";
import Video from "../landingSections/Video";
import AdminContainer from "../components/AdminContainer";
import NavHeader from "../components/NavHeader";
import cr from "../lib/request";
import useTrigger from "../globalState/useTrigger";

export default function Landing() {
  const [data, setData] = useState<LandingData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();

  useEffect(() => {
    setLoading(true);
    const url = `api/content`;
    cr.get(url)
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [trigger]);

  return (
    <AdminContainer active={[3]}>
      <NavHeader label="CMS Landing Page" />

      {!loading && data ? (
        <VStack gap={0} align={"stretch"} scrollBehavior={"smooth"}>
          <LandingNav data={data.nav} />

          <Hero data={data.hero} />

          <Statistik data={data.statistik} />

          <Layanan data={data.layanan} />

          <Fitur data={data.fitur} />

          <Video data={data.video} />

          {/* KASI BLOG API */}
          <Blog data={data.blog} />

          <Footer data={data.footer} />
        </VStack>
      ) : (
        <Center justifyContent={"center"} flex={1}>
          <Spinner />
        </Center>
      )}
    </AdminContainer>
  );
}
