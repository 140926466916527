import { Image, VStack } from "@chakra-ui/react";
import React from "react";

export default function KubuSpinner() {
  return (
    <VStack h={"100vh"} w={"100%"} justify={"center"}>
      <Image src="/logo.png" w={"100px"} animation={"pulse 1000ms infinite"} />
    </VStack>
  );
}
